
<div>
  <GlobalDialog
    :dialogTitle="dialogTitle"
    :dialogVisible.sync="dialogVisible"
    :width="width"
    :footerButtonText="footerButtonText"
    :footerButtonType="footerButtonType"
    :disableCancel="disableCancel"
    :disableButton="disableButton"
    @handleClose="handleClose"
    @handleClick="handleClick"
  >

    <template v-slot:body>
      <div style="margin: 0; padding: 0">
        <!-- <div class="icon-container" @click="handleClose">
          <b-icon
            :style="{
              marginRight: '-8px',
              color: 'black',
              fontSize:'12px',
            }"
            class="crossicon"
            :icon="'x'"
          ></b-icon>
        </div> -->
        
        <div class="delete-body">
          <div class="alert">

            <b-icon
            :style="{
             
              color:iconColor ,
            }"
            class="alert-icon"
            :icon="iconName"
          ></b-icon>
            <!-- <img
              src="../../../../assets/img/alert (1).svg"
              alt="alert"
              class="warningImage"
            /> -->
          </div>
          <p class="info-container">{{ deleteMessage }}</p>
          <p v-if="infoMessage" class="info-container">{{ infoMessage }}</p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer">
        <el-button
        v-if="showNoButton"
        class="btn"
        plain
          @click="handleClose"
         
          >{{ footerCancelBtnText }}</el-button>
        <el-button
        class="btn"
          :type="footerButtonType"
          @click="handleClick"
          :disabled="disableButton"
          :loading="isLoading"
          >{{ footerButtonText }}</el-button
        >
      </div>
    </template>
  
  </GlobalDialog>
</div>
